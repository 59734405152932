import revive from './coming-soon.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={revive} className="App-logo" alt="logo" />
        <h3>
          coming soon
        </h3>

        {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
    </div>
  );
}

export default App;
